import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { firestore } from 'firebase';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Township, Voucher, VoucherGroup } from '../interfaces';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-resend-voucher',
  templateUrl: './resend-voucher.component.html',
  styleUrls: ['./resend-voucher.component.scss'],
})
export class ResendVoucherComponent implements OnInit {
  resendVoucherForm: FormGroup;
  awaitingResponse = true;

  townshipId: string;
  township: Township;
  headerImg: string;

  readyToSend = false;
  isSending = false;
  waitingResponse = false;

  vouchers: Voucher[] = [];
  notification: string;
  fieldToCheck: any[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private translate: TranslateService,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.resendVoucherForm = this.fb.group({
      email: [, [Validators.email]],
      houseNumber: [],
      houseNumberAddition: [],
      postal: [],
      couponNumber: [],
      name: [],
      // terms: [, Validators.required],
      // phone: [],
    });

    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
      console.log('townshipId', this.townshipId);
      this.township = (
        await this.db.doc(`/township/${this.townshipId}`).get().toPromise()
      ).data() as Township;
      console.log('township', this.township);
      if (this.township.headerImageUrl) {
        this.headerImg = this.township.headerImageUrl;
      } else {
        if (this.township.voucherPrefix === 'groene') {
          this.headerImg = '../../assets/images/groene.png';
        } else if (this.township.voucherPrefix === 'duurzaamwonen') {
          this.headerImg = '../../assets/images/duurzaamwonen.png';
        } else {
          this.headerImg = '../../assets/images/lokale.png';
        }
      }
      this.awaitingResponse = false;
      // if (this.township.voucherPrefix === 'groene') {
      //   this.environmentObj = { value: 'Groene Bon' };
      // } else {
      //   this.environmentObj = { value: 'Lokale Bon' };
      // }
    });

    this.resendVoucherForm.valueChanges
      .pipe(debounceTime(600))
      .subscribe(async (form) => {
        console.log('controlName', form);
        this.fieldToCheck = [];
        // this.waitingResponse = true;
        this.readyToSend = false;
        // let voucherRef: AngularFirestoreCollection;
        if (form.couponNumber) {
          this.fieldToCheck.push({ number: form.couponNumber });
        }
        if (form.postal && form.houseNumber) {
          if (form.houseNumberAddition) {
            this.fieldToCheck.push({
              houseNumber: form.houseNumber,
              postal: form.postal,
              houseNumberAddition: form.houseNumberAddition,
            });
          } else {
            this.fieldToCheck.push({
              houseNumber: form.houseNumber,
              postal: form.postal,
            });
          }
        }
        if (form.name) {
          this.fieldToCheck.push({ name: form.name });
        }
        if (form.email) {
          this.fieldToCheck.push({ email: form.email });
        }
      });

    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
  }

  getError(name) {
    const field = this.resendVoucherForm.get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.get(`errors.${error}`).toPromise() as any;
      return res.__zone_symbol__value as string;
    }
    return '';
  }

  async calculateValidUntil(voucherGroup: VoucherGroup, voucher: Voucher) {
    console.log('calculateValidUntil voucherGroup', voucherGroup);
    console.log(
      'calculateValidUntil voucher.activateDate',
      voucher.activateDate
    );
    let validUntilDate;
    if (voucherGroup) {
      if (voucherGroup.validUntilDate) {
        validUntilDate = voucherGroup.validUntilDate.toDate();
      }
      if (voucherGroup.validUntilTime && voucher.activateDate) {
        let activateDate;
        // Since we don't know if the calculateValidUntil function is called with a toDate already called we just try both scenario's
        try {
          activateDate = new Date(voucher.activateDate);
        } catch (e) {
          console.error(e);
          console.log('voucher', voucher);
          // activateDate = new Date(voucher.activateDate);
        }
        console.log('activateDate', activateDate);
        const activateDateWithoutHours = new Date(
          activateDate.getFullYear(),
          activateDate.getMonth(),
          activateDate.getDate() + 1
        );
        const validDate = new Date(activateDateWithoutHours);
        if (
          voucherGroup.validUntilTimeType === 'days' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setDate(
            validDate.getDate() + voucherGroup.validUntilTimeValue
          );
        } else if (
          voucherGroup.validUntilTimeType === 'weeks' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setDate(
            validDate.getDate() + voucherGroup.validUntilTimeValue * 7
          );
        } else if (
          voucherGroup.validUntilTimeType === 'months' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setMonth(
            validDate.getMonth() + voucherGroup.validUntilTimeValue
          );
        } else if (
          voucherGroup.validUntilTimeType === 'years' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setFullYear(
            validDate.getFullYear() + voucherGroup.validUntilTimeValue
          );
        }
        if (!validUntilDate || validDate < validUntilDate) {
          validUntilDate = validDate;
        }
      }
    }
    // Overwrite if specifically mentioned in voucher.
    console.log('voucher.validUntilDate typeof', typeof voucher.validUntilDate);
    if (voucher.validUntilDate && voucher.validUntilDate !== null) {
      if (typeof voucher.validUntilDate === 'string') {
        validUntilDate = new Date(voucher.validUntilDate);
      } else {
        validUntilDate = new Date(voucher.validUntilDate.toDate());
      }
    }
    let validUntilString;
    let reversedString;
    if (validUntilDate) {
      // logger.log('validUntilDate.getFullYear()', validUntilDate.getFullYear());
      // const day = validUntilDate.getDate() === 0 ? validUntilDate.getDate() + 1 : validUntilDate.getDate();
      // logger.log('day', day);
      console.log('validUntilDate', validUntilDate, typeof validUntilDate);
      let day = validUntilDate.getDate().toString();
      if (day.length === 1) {
        day = `0${day}`;
      }
      let month = (validUntilDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = `0${month}`;
      }
      const year = validUntilDate.getFullYear().toString();
      validUntilString = `${day}-${month}-${year}`;
      reversedString = `${year}-${month}-${day}`;
      console.log('string', validUntilString);
      console.log('reversedString', reversedString);
    } else {
      validUntilString = 'onbekend';
      reversedString = 'onbekend';
    }
    const yearFromNow = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    return {
      date: validUntilDate as Date,
      isValid:
        (validUntilDate as Date) >= new Date()
          ? voucher.claimDate
            ? false
            : true
          : false,
      showInUi: validUntilDate
        ? moment(validUntilDate).tz('Europe/Amsterdam').diff(yearFromNow, 'y') <
          -1
          ? false
          : true
        : true,
      string: validUntilString,
      reversedString,
    };
  }

  async getVouchers() {
    if (this.readyToSend) {
      const res = this.translate
        .get(`snackbar-messages.already-retrieved-vouchers`)
        .toPromise() as any;
      return this.snackBar.open(res.__zone_symbol__value as string, 'X', {
        duration: 5000,
      });
    }
    this.waitingResponse = true;
    const requestUrl = `${environment.functionsUrl}/httpGetVoucher`;
    // console.log('requestUrl', requestUrl);
    const postData = {
      fieldToCheck: this.fieldToCheck,
      townshipId: this.townshipId,
    };
    console.log('postData', postData);
    const res = (await this.http.post(requestUrl, postData).toPromise()) as any;
    console.log('res', res.messages);
    if (res.message === 'succeed') {
      if ((res.data && res.data.length === 0) || !res.data) {
        const res = this.translate
          .get(`resend-vouchers.notification`)
          .toPromise() as any;
        this.notification = res.__zone_symbol__value as string;
      } else {
        const tempArr = [];
        for (const voucher of res.data) {
          if (voucher.activateDate) {
            const voucherGroup = (
              await this.db
                .doc(
                  `township/${this.townshipId}/voucherGroups/${voucher.voucherGroupId}`
                )
                .get()
                .toPromise()
            ).data() as VoucherGroup;
            const validateDate = await this.calculateValidUntil(
              voucherGroup,
              voucher
            );
            voucher.validUntilDateObj = validateDate;
            console.log('validDate', validateDate);
            if (validateDate.string !== 'onbekend' && validateDate.showInUi) {
              tempArr.push(voucher);
            } else {
              if (validateDate.showInUi) {
                //validateDate.date && validateDate.date < new Date(
                tempArr.push(voucher);
              }
            }
          }
        }

        console.log('tempArr', tempArr);
        this.vouchers = tempArr;
        this.waitingResponse = false;
        if (tempArr.length === 0) {
          const res = this.translate
            .get(`resend-vouchers.notification`)
            .toPromise() as any;
          this.readyToSend = false;
          this.waitingResponse = false;
          return (this.notification = res.__zone_symbol__value as string);
        } else {
          this.readyToSend = true;
        }
      }
    } else {
      this.vouchers = [];
      const res = this.translate
        .get(`resend-vouchers.notification`)
        .toPromise() as any;
      this.readyToSend = false;
      this.waitingResponse = false;
      return (this.notification = res.__zone_symbol__value as string);
    }
    // res.subscribe((result) => {
    //   console.log('result', result);
    //   this.waitingResponse = false;
    //   this.readyToSend = true;
    //   const res = this.translate
    //     .get(`resend-vouchers.notification-done`)
    //     .toPromise() as any;
    //   this.waitingResponse = false;
    //   const txt = res.__zone_symbol__value as string;
    //   this.snackBar.open(txt, 'X', {
    //     duration: 5000,
    //   });
    // });
  }

  submit() {
    // this.waitingResponse = true;
    this.isSending = true;
    const requestUrl = `${environment.functionsUrl}/httpResendVouchers`;
    // console.log('requestUrl', requestUrl);
    const postData = {
      vouchers: this.vouchers,
      townshipId: this.townshipId,
    };

    try {
      const res = this.http.post(requestUrl, postData);
      res.subscribe((result) => {
        console.log('result', result);
        this.waitingResponse = false;
        this.readyToSend = false;
        this.isSending = false;
        const res = this.translate
          .get(`resend-vouchers.notification-done`)
          .toPromise() as any;
        // this.waitingResponse = false;
        // this.vouchers = [];
        this.resendVoucherForm.reset();
        this.vouchers = [];
        const txt = res.__zone_symbol__value as string;
        this.snackBar.open(txt, 'X', {
          duration: 7500,
        });
      });
    } catch (e) {
      this.waitingResponse = false;
      this.readyToSend = false;
      this.isSending = false;
      console.error(e);
      const res = this.translate
        .get(`snackbar-messages.something-went-wrong`)
        .toPromise() as any;
      // this.waitingResponse = false;
      this.vouchers = [];
      this.resendVoucherForm.reset();
      const txt = res.__zone_symbol__value as string;
      this.snackBar.open(txt, 'X', {
        duration: 7500,
      });
    }
  }
}
