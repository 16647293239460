import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Township, VoucherGroup } from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { environment } from 'src/environments/environment';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-buy-vouchers',
  templateUrl: './buy-vouchers.component.html',
  styleUrls: ['./buy-vouchers.component.scss'],
})
export class BuyVouchersComponent implements OnInit {
  env = environment;
  iframeError = false;
  awaitingResponse: boolean;
  townshipId: string;
  voucherGroupId: string;
  township: Observable<Township>;
  townshipDoc: AngularFirestoreDocument<Township>;
  voucherGroup: Observable<VoucherGroup>;
  voucherGroupData: VoucherGroup;
  voucherGroupDoc: AngularFirestoreDocument<VoucherGroup>;
  buyForm: FormGroup;
  personalDetailsForm: FormGroup;
  submitForm: FormGroup;
  availableVouchers: number;
  termsRows: number;
  headerImg: string;

  sisowBanks: any[] = [];
  paymentSystem: string;

  envName: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.personalDetailsForm = this.fb.group({
      type: ['customer'],
      name: ['', Validators.required],
      email: [, [Validators.required, Validators.email]],
      emailConfirm: [, [Validators.required, Validators.email]],
      postal: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
    });
    this.buyForm = this.fb.group({
      amount: [, [Validators.required, Validators.min(1)]],
    });
    this.submitForm = this.fb.group({
      terms: [false, Validators.requiredTrue],
    });
    this.route.params.subscribe((params) => {
      this.townshipId = params.townshipId;
      this.voucherGroupId = params.voucherGroupId;
      if (this.townshipId && this.voucherGroupId) {
        this.getInfo();
      } else {
        this.iframeError = true;
      }
    });
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.personalDetailsForm.controls.type.valueChanges.subscribe((value) => {
      if (value === 'company') {
        this.personalDetailsForm.addControl(
          'companyName',
          new FormControl('', Validators.required)
        );
      } else if (this.personalDetailsForm.controls.companyName) {
        this.personalDetailsForm.removeControl('companyName');
      }
    });
    this.personalDetailsForm.controls.email.valueChanges.subscribe((value) => {
      this.checkEmails(value, this.personalDetailsForm.value.emailConfirm);
    });
    this.personalDetailsForm.controls.emailConfirm.valueChanges.subscribe(
      (value) => {
        this.checkEmails(this.personalDetailsForm.value.email, value);
      }
    );
  }
  checkEmails(email, emailConfirm) {
    if (
      this.personalDetailsForm.controls.email.dirty &&
      this.personalDetailsForm.controls.emailConfirm.dirty &&
      email !== emailConfirm
    ) {
      this.personalDetailsForm.controls.emailConfirm.setErrors({
        emailsNeedToBeTheSame: true,
      });
    } else if (
      this.personalDetailsForm.controls.emailConfirm.hasError(
        'emailsNeedToBeTheSame'
      )
    ) {
      this.personalDetailsForm.controls.emailConfirm.setErrors(null);
      this.personalDetailsForm.controls.emailConfirm.updateValueAndValidity();
    }
  }
  getError(name) {
    const field = this.personalDetailsForm.get(name);
    if (field.touched || !field.pristine) {
      let error;

      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.get(`errors.${error}`).toPromise() as any;
      // console.log('res', res.__zone_symbol__value);
      return res.__zone_symbol__value as string;
    }
    return '';
  }
  getInfo() {
    this.townshipDoc = this.db.doc<Township>('township/' + this.townshipId);
    this.township = this.townshipDoc.valueChanges();
    this.township.subscribe((township) => {
      console.log('township', township);
      if (township.headerImageUrl) {
        this.headerImg = township.headerImageUrl;
      } else {
        if (township.voucherPrefix === 'groene') {
          this.headerImg = '../../assets/images/groene.png';
        } else if (township.voucherPrefix === 'toegangs') {
          this.headerImg = '../../assets/images/toegangsbon.png';
        } else if (township.voucherPrefix === 'duurzaamwonen') {
          this.headerImg = '../../assets/images/duurzaamwonen.png';
        } else {
          this.headerImg = '../../assets/images/lokale.png';
        }
      }
      if (township.voucherPrefix === 'groene') {
        this.envName = { value: 'Groene Bon' };
      } else if (township.voucherPrefix === 'toegangs') {
        this.envName = { value: 'Toegangsbon' };
      } else if (township.voucherPrefix === 'duurzaamwonen') {
        this.envName = { value: 'Duurzaam Wonen Bon' };
      } else {
        this.envName = { value: 'Lokale Bon' };
      }

      this.paymentSystem = township.paymentSystem;
      // if (township.paymentSystem === 'sisow') {
      // } else {
      //   // TODO retrieve banks from mollie
      // }
    });

    this.voucherGroupDoc = this.db.doc<VoucherGroup>(
      'township/' + this.townshipId + '/voucherGroups/' + this.voucherGroupId
    );
    // this.voucherGroup = this.voucherGroupDoc.valueChanges();
    this.voucherGroup = this.voucherGroupDoc.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as VoucherGroup;
        this.termsRows = 5;
        console.log('data', data);
        data.id = actions.payload['id'];

        if (data.requestPhone) {
          this.personalDetailsForm.addControl(
            'phone',
            new FormControl('', Validators.required)
          );
        }
        if (data.type === 'claim') {
          this.personalDetailsForm.addControl(
            'number',
            new FormControl('', Validators.required)
          );
        }

        console.log('data', data);
        data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
        data.activatedVouchers = data.activatedVouchers
          ? data.activatedVouchers
          : 0;
        data.reservedVouchers = data.reservedVouchers
          ? data.reservedVouchers
          : 0;
        // console.log('totalVouchers', data.totalVouchers);
        // console.log('maxVouchers', data.maxVouchers);
        // console.log('activatedVouchers', data.activatedVouchers);
        // console.log('reservedVouchers', data.reservedVouchers);
        if (data.maxVouchers && data.totalVouchers > data.maxVouchers) {
          data.totalVouchers = data.maxVouchers;
        }
        if (!data.claimedVouchers) {
          data.claimedVouchers = 0;
        }
        if (!data.activatedVouchers) {
          data.activatedVouchers = 0;
        }
        if (!data.reservedVouchers || data.reservedVouchers < 0) {
          data.reservedVouchers = 0;
        }
        if (!data.toBeActivated) {
          data.toBeActivated = 0;
        }

        const reservedVouchers = data.reservedVouchers + data.toBeActivated;
        this.availableVouchers =
          data.totalVouchers - data.activatedVouchers - reservedVouchers;

        console.log('this.availableVouchers', this.availableVouchers);
        if (this.availableVouchers <= 0 && data.type !== 'requestActivated') {
          this.buyForm.disable();
          this.personalDetailsForm.disable();
          this.submitForm.disable();
        } else {
          this.buyForm.enable();
          this.personalDetailsForm.enable();
          this.submitForm.enable();
        }
        if (data.useHeaderImage) {
          this.headerImg = data.headerImageUrl;
        }
        return { ...data };
      })
    );
    this.voucherGroup.subscribe((voucherGroup) => {
      console.log('voucherGroup', voucherGroup);
      this.voucherGroupData = voucherGroup;
    });
  }
  getCurrencyString(number) {
    let newNumber;
    if (!number.toString().includes('.')) {
      // this isnt a decimal
      newNumber = `${number.toString()},-`;
    } else {
      newNumber = `${number.toFixed(2)}`;
      newNumber = newNumber.replace('.', ',');
    }
    return newNumber;
  }
  ceil(number) {
    return Math.ceil(number);
  }
  async submit(issuerId?: number) {
    if (!this.submitForm.valid) {
      this.snackBar.open('Je moet de voorwaarden eerst accepteren', 'X', {
        duration: 2500,
      });
      return;
    }
    if (!this.awaitingResponse && this.availableVouchers > 0) {
      this.awaitingResponse = true;
      const email = this.personalDetailsForm.get('email').value as string;
      const emailConfirm = this.personalDetailsForm.get('emailConfirm')
        .value as string;
      const amount = this.buyForm.value.amount;
      if (email !== emailConfirm) {
        this.awaitingResponse = undefined;
        this.snackBar.open('Je e-mails komen niet overeen.', '', {
          duration: 5000,
        });
        return;
      }

      let postal: string;
      let houseNumber: string;
      let houseNumberAddition: string;
      // if (!this.voucherGroupData.dontRequestAddress) {
      postal = this.personalDetailsForm.get('postal').value as string;
      postal = postal.toUpperCase().replace(/\s/g, '');
      // }
      const code = this.personalDetailsForm.get('number')
        ? this.personalDetailsForm.get('number').value
        : null;
      const name = this.personalDetailsForm.get('name')
        ? this.personalDetailsForm.get('name').value
        : null;
      const phone = this.personalDetailsForm.get('phone')
        ? this.personalDetailsForm.get('phone').value
        : null;
      const city = this.personalDetailsForm.get('city')
        ? this.personalDetailsForm.get('city').value
        : null;
      const address = this.personalDetailsForm.get('address')
        ? this.personalDetailsForm.get('address').value
        : null;
      const companyName =
        this.personalDetailsForm.value.type === 'company'
          ? this.personalDetailsForm.value.companyName
          : null;
      let res: Observable<any>;
      console.log('email', email);
      console.log('postal', postal);
      console.log('code', code);
      const requestUrl = `${this.env.functionsUrl}/httpBuyVouchers`;

      // let requestUrl = `${this.env.functionsUrl}/httpBuyVouchers?townshipId=${this.townshipId}&voucherGroupId=${this.voucherGroupId}&email=${email}&amount=${amount}`;
      const postData = {
        townshipId: this.townshipId,
        voucherGroupId: this.voucherGroupId,
        email,
        amount,
        name,
        postal,
        city,
        address,
      } as any;

      if (this.paymentSystem === 'sisow') {
        postData.issuerId = issuerId;
      }
      // if (this.submitForm.get('bank')) {
      //   postData.issuerId = this.submitForm.get('bank').value;
      // }
      if (companyName) {
        postData['companyName'] = companyName;
      }
      if (phone) {
        postData['phone'] = phone;
      }
      res = this.http.post(requestUrl, postData);
      res.subscribe((result) => {
        console.log('result', result);
        if (result.status === 'success') {
          console.log('result', result);
          // redirect to pay screen
          const windowRef = window.open(
            result.redirectUrl,
            '_top',
            'width=400,height=800'
          );
          windowRef.focus();
          this.router.navigate(['/complete-order']);
          // this.stepper.reset();
          // this.personalDetailsForm.controls.type.setValue('customer');
          // this.awaitingResponse = undefined;
        } else {
          this.getTranslation(result);
        }
        //  else if (
        //   result.status === 'invalid_code' ||
        //   result.status === 'already_activated'
        // ) {
        //   this.snackBar.open('De code is ongeldig, of al geactiveerd.', '', {
        //     duration: 12000,
        //   });
        // } else if (result.status === 'invalid_postal') {
        //   this.snackBar.open('De postcode klopt niet.', '', {
        //     duration: 12000,
        //   });
        // } else if (result.status === 'invalid_address') {
        //   this.snackBar.open('Het adres klopt niet.', 'X', {
        //     duration: 12000,
        //   });
        // } else if (result.status === 'postal_already_used') {
        //   this.snackBar.open(
        //     'Er is al een Groene Bon op dit adres geclaimd.',
        //     '',
        //     {
        //       duration: 12000,
        //     }
        //   );
        // } else if (result.status === 'email_already_used') {
        //   this.snackBar.open(
        //     'Er is al een Groene Bon op dit mailadres geclaimd.',
        //     '',
        //     {
        //       duration: 12000,
        //     }
        //   );
        // } else if (result.status === 'incorrect_group_code') {
        //   this.snackBar.open(
        //     'Deze code is gekoppeld aan een andere groep.',
        //     '',
        //     {
        //       duration: 12000,
        //     }
        //   );
        // } else if (
        //   result.status === 'invalid_code' ||
        //   result.status === 'already_activated'
        // ) {
        //   this.snackBar.open('De code is ongeldig, of al geactiveerd.', '', {
        //     duration: 12000,
        //   });
        // } else if (result.status === 'no_vouchers_remaining') {
        //   this.snackBar.open(
        //     'Er is geen geldige groene bon beschikbaar voor dit adres.',
        //     '',
        //     {
        //       duration: 12000,
        //     }
        //   );
        // } else if (result.status === 'invalid_address') {
        //   this.snackBar.open(
        //     'Dit adres komt niet in aanmerking voor deze groene bon.',
        //     '',
        //     {
        //       duration: 12000,
        //     }
        //   );
        // } else {
        //   this.snackBar.open(
        //     'Er is op dit moment geen geldige Groene Bon beschikbaar voor dit adres.',
        //     '',
        //     {
        //       duration: 12000,
        //     }
        //   );
        // }
      });
    }
  }

  getTranslation(objName): void {
    this.translate
      .get(`snackbar-messages.${objName}`)
      .subscribe((res: string) => {
        this.snackBar.open(res, '', {
          duration: 12000,
        });
        return res;
      });
  }
}
