import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Township, Voucher, VoucherGroup } from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-saldo-checker',
  templateUrl: './saldo-checker.component.html',
  styleUrls: ['./saldo-checker.component.scss'],
})
export class SaldoCheckerComponent implements OnInit {
  env = environment;
  awaitingResponse: boolean;
  townshipId: string;
  township: any;
  townshipDoc: AngularFirestoreDocument<Township>;
  saldoCheckerForm: FormGroup;

  environmentObj: any;
  error: string;
  recaptcha: boolean;

  recaptchaKey: string;
  headerImg: string;

  resultString: string;
  resultAmount?: number;
  resultDate: string;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.saldoCheckerForm = this.fb.group({
      voucher: [, Validators.required],
      // phone: [],
    });
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.recaptcha = false;
    this.recaptchaKey = this.env.recaptchaPublicKey;
    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
      this.township = (
        await this.db.doc(`township/${this.townshipId}`).get().toPromise()
      ).data();
      if (this.township.headerImageUrl) {
        this.headerImg = this.township.headerImageUrl;
      } else {
        if (this.township.voucherPrefix === 'groene') {
          this.headerImg = '../../assets/images/groene.png';
        } else if (this.township.voucherPrefix === 'toegangs') {
          this.headerImg = '../../assets/images/toegangsbon.png';
        } else if (this.township.voucherPrefix === 'duurzaamwonen') {
          this.headerImg = '../../assets/images/duurzaamwonen.png';
        } else {
          this.headerImg = '../../assets/images/lokale.png';
        }
      }
      // if (this.township.voucherPrefix === 'groene') {
      //   this.envName = { value: 'Groene Bon' };
      // } else if (this.township.voucherPrefix === 'toegangs') {
      //   this.envName = { value: 'Toegangs Bon' };
      // } else {
      //   this.envName = { value: 'Lokale Bon' };
      // }
      if (this.township.voucherPrefix === 'groene') {
        this.environmentObj = { value: 'Groene Bon' };
      } else if (this.township.voucherPrefix === 'toegangs') {
        this.environmentObj = { value: 'Toegangsbon' };
      } else if (this.township.voucherPrefix === 'duurzaamwonen') {
        this.environmentObj = { value: 'Duurzaam Wonen Bon' };
      } else {
        this.environmentObj = { value: 'Lokale Bon' };
      }
    });
  }

  getError(name) {
    const field = this.saldoCheckerForm.get(name);
    if (field.touched || !field.pristine) {
      let error;

      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.get(`errors.${error}`).toPromise() as any;
      // console.log('res', res.__zone_symbol__value);
      return res.__zone_symbol__value as string;
    }
  }
  async checkSaldo() {
    this.resultDate = null;
    this.resultString = null;
    this.awaitingResponse = true;
    if (this.saldoCheckerForm.valid) {
      const voucherNumber = this.saldoCheckerForm.value.voucher;
      if (!voucherNumber) {
        this.snackBar.open('Lorum ipsum', 'X', {
          duration: 5000,
        }); // add translation
      }

      const requestUrl = `${this.env.functionsUrl}/httpCheckSaldo`;
      let res: Observable<any>;
      res = this.http.post(requestUrl, {
        voucherNumber,
        townshipId: this.townshipId,
      });
      res.subscribe((result) => {
        console.log('result', result);
        switch (result.type) {
          case 'reserved':
            this.resultString = this.getTranslation(result.type, [
              result.voucherNumber,
              result.value,
            ]);
            break;
          case 'claimed':
            this.resultString = this.getTranslation(result.type, [
              result.voucherNumber,
              result.value,
            ]);
            console.log('result.validUntilDate', result.validUntilDate);
            if (result.validUntilDate !== 'onbekend') {
              this.resultDate = this.getTranslation('validUntilDate', [
                result.validUntilDate,
              ]);
            } else {
              this.resultDate = this.getTranslation('noUntilValidDate');
            }
            break;
          case 'expired':
            this.resultString = this.getTranslation('expired', [
              result.voucherNumber,
            ]);
            break;
          case 'activated':
            this.resultString = this.getTranslation(result.type, [
              result.voucherNumber,
              result.value,
            ]);
            console.log('result.validUntilDate', result.validUntilDate);
            if (result.validUntilDate !== 'onbekend') {
              this.resultDate = this.getTranslation('validUntilDate', [
                result.validUntilDate,
              ]);
            } else {
              this.resultDate = this.getTranslation('noUntilValidDate');
            }
            break;
          case 'unknown_voucher':
            this.resultString = this.getTranslation(result.type);
            break;
          case 'not_used':
            this.resultString = this.getTranslation(result.type);
            break;
        }
        this.awaitingResponse = false;
        console.log('resultString', this.resultString);
      });
    }
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse) {
      this.recaptcha = true;
    } else {
      this.recaptcha = false;
    }
  }

  getTranslation(objName, values?): string {
    let newObj = {};
    if (values) {
      if (values.length === 2) {
        newObj = {
          value: values[0],
          amount: values[1],
        };
      } else if (values.length === 1) {
        newObj = {
          value: values[0],
        };
      }
    }
    console.log('newObj', newObj);
    const message = this.translate
      .get(`saldo-checker.messages.${objName}`, newObj)
      .toPromise() as any;
    return message.__zone_symbol__value;
  }
}
