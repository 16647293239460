import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
// import {
//   redirectUnauthorizedTo,
//   redirectLoggedInTo
// } from '@angular/fire/auth-guard';
// import { SelectEntityComponent } from './select-entity/select-entity.component';
// import { environment } from 'src/environments/environment';
import { ActivateVoucherComponent } from './activate-voucher/activate-voucher.component';
import { ExchangeVoucherComponent } from './exchange-voucher/exchange-voucher.component';
import { BuyVouchersComponent } from './buy-vouchers/buy-vouchers.component';
import { BuySuccessComponent } from './buy-vouchers/buy-success/buy-success.component';
import { RequestVouchersComponent } from './request-vouchers/request-vouchers.component';
import { CompleteOrderComponent } from './buy-vouchers/complete-order/complete-order.component';
import { SaldoCheckerComponent } from './saldo-checker/saldo-checker.component';
import { ResendVoucherComponent } from './resend-voucher/resend-voucher.component';
import { GroupLinkComponent } from './group-link/group-link.component';
import { LandingEmailComponent } from './landing-email/landing-email.component';

// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
// const redirectLoggedInToDefault = () => redirectLoggedInTo(['dashboardtownship']);
// const goToTaskDetail = (next) => hasCustomClaim(`task-${next.params.id}`);

// tslint:disable-next-line: prefer-const
const routes: Routes = [
  { path: '', component: ActivateVoucherComponent },
  {
    path: 'activate/:townshipId/:voucherGroupId',
    component: ActivateVoucherComponent,
  },
  {
    path: 'exchange/:townshipId/:organisationId',
    component: ExchangeVoucherComponent,
  },
  {
    path: 'buy/:townshipId/:voucherGroupId',
    component: BuyVouchersComponent,
  },
  {
    path: 'success',
    component: BuySuccessComponent,
  },
  {
    path: 'success/:townshipId/:voucherGroupdId/:orderId',
    component: BuySuccessComponent,
  },
  {
    path: 'request/:townshipId/:type',
    component: RequestVouchersComponent,
  },
  {
    path: 'complete-order',
    component: CompleteOrderComponent,
  },
  {
    path: 'complete-order/:orderId',
    component: CompleteOrderComponent,
  },
  {
    path: 'saldo-checker/:townshipId',
    component: SaldoCheckerComponent,
  },
  {
    path: 'resend-voucher/:townshipId',
    component: ResendVoucherComponent,
  },
  {
    path: 'group-link/:townshipId/:groupLink',
    component: GroupLinkComponent,
  },
  {
    path: 'email',
    component: LandingEmailComponent,
  },
];
// let routes: Routes = [];
// const allRoutes = {
//   default: {
//     path: '**',
//     component: ActivateVoucherComponent
//   },
//   activatevoucher: {
//     path: 'activate/:townshipId/:voucherGroupId',
//     component: ActivateVoucherComponent
//   },
//   // users: {
//   //   path: 'users',
//   //   component: UsersComponent,
//   //   canActivate: [AngularFireAuthGuard],
//   //   data: { authGuardPipe: redirectUnauthorizedToLogin },
//   // },
//   // frequentAddress: {
//   //   path: 'frequentAddress',
//   //   component: FrequentAddressComponent,
//   // },
//   // audit: {
//   //   path: 'audit',
//   //   component: AuditComponent,
//   //   canActivate: [AngularFireAuthGuard],
//   //   data: { authGuardPipe: redirectUnauthorizedToLogin },
//   // },
// // };
// console.log('allroutes', allRoutes);
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

console.log('routes', routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
// export class AppRoutingModule {
//   constructor(router: Router) {
//     const config = router.config;
//     environment.routes.forEach(route => {
//       config.push(allRoutes[route]);
//     });
//     // config.push({
//     //   path: '**',
//     //   redirectTo: 'dashboard-township',
//     //   canActivate: [AngularFireAuthGuard],
//     //   data: { authGuardPipe: redirectUnauthorizedToLogin },
//     // });
//     router.resetConfig(config);
//     console.log('router3', router);
//   }
// }
