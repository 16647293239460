export const environment = {
  production: false,
  language: 'en',
  firebase: {
    apiKey: 'AIzaSyCBi6Dvcx73fZwU5HnMn2jUarL4viX05L8',
    authDomain: 'wemaron-vouchers-dev.firebaseapp.com',
    databaseURL: 'https://wemaron-vouchers-dev.firebaseio.com',
    projectId: 'wemaron-vouchers-dev',
    storageBucket: 'wemaron-vouchers-dev.appspot.com',
    messagingSenderId: '804844036454',
    appId: '1:804844036454:web:b365c1a59fba197dca28a7',
    measurementId: 'G-JQ5FFXX8MT',
  },
  functionsUrl: 'https://us-central1-wemaron-vouchers-dev.cloudfunctions.net',
  iframeUrl: 'https://dev.frame.lokalebon.nl/',
  loginMethod: {
    phone: false,
    email: true,
  },
  background: {
    planner:
      'https://firebasestorage.googleapis.com/v0/b/buurtvervoer-wijkbijduurstede.appspot.com/o/Headerplanningapp.fw.png?alt=media&token=87116985-0fbe-418d-ab0b-777cdbae3677',
    profile:
      'https://firebasestorage.googleapis.com/v0/b/buurtvervoer-wijkbijduurstede.appspot.com/o/Afbeelding%20voor%20Login%20WIjkse%20dienst-03.svg?alt=media&token=6baed3a5-000d-4462-a241-dc8b9f4b5a0a',
  },
  login: {
    image:
      'https://firebasestorage.googleapis.com/v0/b/buurtvervoer-wijkbijduurstede.appspot.com/o/Afbeelding%20voor%20Login%20WIjkse%20dienst-03.svg?alt=media&token=6baed3a5-000d-4462-a241-dc8b9f4b5a0a',
  },
  name: {
    name: 'Vouchers',
  },
  phone: {
    nl: '+316',
    us: '+1',
  },
  routes: [
    'login',
    'planner',
    'dashboardtownship',
    'vouchermanagement',
    'organisationmanagement',
  ],
  recaptchaPublicKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};
