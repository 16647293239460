import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import {
  Township,
  VoucherGroup,
  Discount,
  Voucher,
  Organisation,
  Tag,
} from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-exchange-voucher',
  templateUrl: './exchange-voucher.component.html',
  styleUrls: ['./exchange-voucher.component.scss'],
})
export class ExchangeVoucherComponent implements OnInit {
  env = environment;
  iframeError = false;
  townshipId: string;
  organisationId: string;
  township: Observable<Township>;
  townshipDoc: AngularFirestoreDocument<Township>;
  orgDoc: AngularFirestoreDocument<Organisation>;
  orgName: string;
  tagDoc: AngularFirestoreCollection<Tag>;
  exchangeVoucherForm: FormGroup;

  txt: string;
  url: string;
  termsUrl: string;

  error: string;

  awaitingResponse: boolean;
  waiting: boolean;

  tags: any[];
  explanation: string;

  showEmail: boolean;
  voucher: Voucher;
  discount: Discount;

  envName: any = {};
  headerImg: string;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.exchangeVoucherForm = this.fb.group({
      email: [, [Validators.required, Validators.email]],
      emailConfirm: [, [Validators.required, Validators.email]],
      voucher: [, Validators.required],
      explanation: [, ''],
      // terms: [, Validators.required],
      // phone: [],
    });
    this.awaitingResponse = true;

    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.route.params.subscribe((params) => {
      this.townshipId = params.townshipId;
      this.organisationId = params.organisationId;
      if (this.townshipId && this.organisationId) {
        console.log(' this.organisationId', this.organisationId);
        console.log('this.townshipId', this.townshipId);
        this.getInfo();
      } else {
        this.iframeError = true;
      }
    });

    this.exchangeVoucherForm.controls.voucher.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged())
      .subscribe((result) => {
        if (result !== '') {
          this.explanation = '';
          this.showEmail = true;
          const requestUrl = `${this.env.functionsUrl}/httpGetDiscount?townshipId=${this.townshipId}&voucher=${result}&orgId=${this.organisationId}&name=${this.orgName}`;
          let res: Observable<any>;
          console.log('req', requestUrl);
          res = this.http.get(requestUrl, { responseType: 'text' });
          res.subscribe((result) => {
            if (result !== '') {
              console.log('result', result);
              this.showEmail = true;
              this.exchangeVoucherForm.controls.explanation.setValue(result);
              this.explanation = result;
            } else {
              this.showEmail = true;
              this.explanation = '';
            }
          });
        }
      });
  }

  getInfo(): void {
    this.townshipDoc = this.db.doc<Township>('township/' + this.townshipId);
    this.township = this.townshipDoc.valueChanges();
    this.township.subscribe((township) => {
      console.log('township', township);
      // if (township.voucherPrefix === 'groene') {
      //   this.txt = 'Groene Bon';
      //   this.envName = { value: 'Groene Bon' };
      // } else {
      //   this.txt = 'Lokale Bon';
      //   this.envName = { value: 'Lokale Bon' };
      // }

      if (township.headerImageUrl) {
        this.headerImg = township.headerImageUrl;
      } else {
        if (township.voucherPrefix === 'groene') {
          this.headerImg = '../../assets/images/groene.png';
        } else if (township.voucherPrefix === 'toegangs') {
          this.headerImg = '../../assets/images/toegangsbon.png';
        } else if (township.voucherPrefix === 'duurzaamwonen') {
          this.headerImg = '../../assets/images/duurzaamwonen.png';
        } else {
          this.headerImg = '../../assets/images/lokale.png';
        }
      }
      if (township.voucherPrefix === 'groene') {
        this.envName = { value: 'Groene Bon' };
        this.txt = ' Groene Bon';
      } else if (township.voucherPrefix === 'toegangs') {
        this.envName = { value: 'Toegangsbon' };
        this.txt = 'Toegangsbon';
      } else if (township.voucherPrefix === 'duurzaamwonen') {
        this.envName = { value: 'Duurzaam Wonen Bon' };
        this.txt = 'Duurzaam Wonen Bon';
      } else {
        this.envName = { value: 'Lokale Bon' };
        this.txt = 'Lokale Bon';
      }
      this.termsUrl = township.termsUrl;
      this.awaitingResponse = false;
    });
    this.orgDoc = this.db.doc<Organisation>(
      'township/' + this.townshipId + '/organisations/' + this.organisationId
    );
    this.orgDoc.valueChanges().subscribe((result) => {
      console.log('result', result);
      this.url = result.website;
      this.orgName = result.name;
      if (result.explanation) {
        this.explanation = result.explanation;
      }
    });
  }

  async submit(): Promise<any> {
    if (this.exchangeVoucherForm.valid) {
      this.waiting = true;
      console.log(this.orgName);

      let requestUrl = '';

      const data = this.exchangeVoucherForm.value;
      if (data.email !== data.emailConfirm) {
        this.error = this.getTranslation('email-dont-match');
        this.exchangeVoucherForm.controls.emailConfirm.setErrors({
          notMatched: true,
        });
        this.waiting = false;
        return;
      }

      // create http req
      requestUrl = `${this.env.functionsUrl}/httpExchangeVoucher?townshipId=${this.townshipId}&voucher=${data.voucher}&orgId=${this.organisationId}&url=${this.url}&email=${data.email}&name=${this.orgName}`;
      let res: Observable<any>;
      console.log('req', requestUrl);
      res = this.http.get(requestUrl, { responseType: 'text' });
      res.subscribe((result) => {
        // console.log('result', result);
        switch (result) {
          default:
            this.snackBar.open(
              this.getTranslation('something-went-wrong'),
              'x',
              {
                duration: 5000,
              }
            );
            break;
          case 'Bon bestaat niet.':
            this.showError(this.getTranslation('invalid_code'));
            break;
          case 'Deze bon is al geclaimd.':
            this.showError(this.getTranslation('already_claimd'));
            break;
          case 'Er is geen kortingscode beschikbaar.':
            this.showError(this.getTranslation('all_discounts_exchanged'));
            break;
          case 'Alle kortingscodes zijn ingewisseld.':
            this.showError(this.getTranslation('all_discounts_exchanged'));
            break;
          case 'Uw heeft geen rechten om deze bon in te wisselen.':
            this.showError(this.getTranslation('no_rights'));
            break;
          case 'succeed':
            this.snackBar.open(this.getTranslation('exchange_succeed'), 'x', {
              duration: 5000,
            });
            this.exchangeVoucherForm.reset();
            break;
        }
        this.waiting = false;
      });
    }
  }

  showError(txt: string): void {
    this.error = txt;
    this.exchangeVoucherForm.controls.voucher.setErrors({
      notMatched: true,
    });
    this.waiting = false;
  }

  getTranslation(objName): string {
    const message = this.translate
      .get(`snackbar-messages.${objName}`)
      .toPromise() as any;
    return message.__zone_symbol__value;
  }
}
