import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Township,
  Voucher,
  VoucherGroup,
} from '../../../../admin-ng/src/app/interfaces';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Canvas from 'canvas';
import * as JsBarcode from 'jsbarcode';
// import * as moment from 'moment';

@Component({
  selector: 'app-landing-email',
  templateUrl: './landing-email.component.html',
  styleUrls: ['./landing-email.component.scss'],
})
export class LandingEmailComponent implements OnInit {
  waiting: boolean = true;
  dataToDisplay: {
    header: string;
    voucherGroup: VoucherGroup;
    township: Township;
    validUntilDate: any;
    voucher: Voucher;
    validDate: boolean;
  };
  voucherGroup: VoucherGroup;
  township: Township;
  townshipId: string;
  voucher: Voucher;
  code: string; // number is not accepted by plain js in parameters
  barcodeUrl: string;

  validCoupon: boolean = true;
  resendingEmail: boolean;

  constructor(private http: HttpClient, private snackbar: MatSnackBar) {}

  async ngOnInit(): Promise<any> {
    const url = new URL(window.location.href);
    this.code = url.searchParams.get('code');
    this.townshipId = url.searchParams.get('townshipId');
    const response = (await this.http
      .post(`${environment.functionsUrl}/httpGetEmailData`, {
        code: this.code,
        townshipId: this.townshipId,
      })
      .toPromise()) as any;
    console.log('response', response);
    if (response.message === 'succeed') {
      this.dataToDisplay = response.data;
      console.log('dataToDisplay', this.dataToDisplay);
      this.voucherGroup = this.dataToDisplay.voucherGroup;
      this.township = this.dataToDisplay.township;
      this.voucher = this.dataToDisplay.voucher;
      if (this.voucher.claimDate) {
        this.validCoupon = false;
      }
      if (!this.dataToDisplay.validDate) {
        this.validCoupon = false;
      }
      // this.township.id = townshipId;
      // this.voucherGroup.id = this.voucher.voucherGroupId;
      if (this.voucherGroup.name === 'ZZZ vervallen') {
        return this.snackbar.open('Deze bon is vervallen', 'X', {
          duration: 5000,
        });
      }

      const canvas = Canvas.createCanvas(400, 200);
      JsBarcode(canvas, this.code, {
        marginLeft: 48,
        marginRight: 48,
        marginTop: 10,
        marginBottom: 10,
      });
      this.barcodeUrl = canvas.toDataURL();

      this.waiting = false;
    } else {
      this.snackbar.open(
        'Er is iets misgegaan bij het ophalen van je gegevens',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }

  async resendEmail() {
    this.resendingEmail = true;
    try {
      const response = (await this.http
        .post(`${environment.functionsUrl}/httpSendVoucherMail`, {
          code: this.code,
          email: this.voucher.email,
          townshipId: this.townshipId,
        })
        .toPromise()) as any;
      console.log('response', response);
      if (response) {
        this.snackbar.open('E-mail is opnieuw verzonden', 'X', {
          duration: 5000,
        });
      } else {
        this.snackbar.open(
          'Er is iets misgegaan met opnieuw verzenden van de e-mail',
          'X',
          {
            duration: 5000,
          }
        );
      }
      this.resendingEmail = false;
    } catch (e) {
      console.error(e);
      this.resendingEmail = false;
      this.snackbar.open(
        'Er is iets misgegaan met opnieuw verzenden van de e-mail',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }
}
