import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentData,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Township, Voucher, VoucherGroup } from '../interfaces';

@Component({
  selector: 'app-group-link',
  templateUrl: './group-link.component.html',
  styleUrls: ['./group-link.component.scss'],
})
export class GroupLinkComponent implements OnInit {
  groupLink: string;
  townshipId: string;

  township: Township;
  headerImg: string;

  groupLinkForm: FormGroup;
  voucherGroups: VoucherGroup[] = [];

  waiting = false;
  groupLinkTxt: string;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.groupLinkForm = this.fb.group({
    //   voucherGroup: [, Validators.required],
    //   // phone: [],
    // });

    this.route.params.subscribe(async (params) => {
      this.groupLink = params.groupLink;
      this.townshipId = params.townshipId;
      console.log('groupLink', this.groupLink);

      this.township = (
        await this.db.doc(`township/${this.townshipId}`).get().toPromise()
      ).data() as Township;

      if (this.township.headerImageUrl) {
        this.headerImg = this.township.headerImageUrl;
      } else {
        if (this.township.voucherPrefix === 'groene') {
          this.headerImg = '../../assets/images/groene.png';
        } else if (this.township.voucherPrefix === 'toegangs') {
          this.headerImg = '../../assets/images/toegangsbon.png';
        } else if (this.township.voucherPrefix === 'duurzaamwonen') {
          this.headerImg = '../../assets/images/duurzaamwonen.png';
        } else {
          this.headerImg = '../../assets/images/lokale.png';
        }
      }
      const voucherGroups = await this.db
        .collection<VoucherGroup>(
          `township/${this.townshipId}/voucherGroups`,
          (ref) => ref.where('groupLink', '==', this.groupLink)
        )
        .valueChanges({ idField: 'id' });
      voucherGroups.subscribe((newVoucherGroups) => {
        console.log('newVoucherGroups', newVoucherGroups);
        const updatedVoucherGroups = [];
        newVoucherGroups.forEach((voucherGroup: VoucherGroup) => {
          const updatedVoucherGroup = voucherGroup as any;
          console.log('location', location);
          let url = environment.iframeUrl;
          if (voucherGroup.type === 'sell') {
            url += 'buy/';
          } else {
            url += 'activate/';
          }
          url += `${this.townshipId}/${voucherGroup.id}`;
          updatedVoucherGroup.url = url;
          updatedVoucherGroups.push(updatedVoucherGroup);
        });
        this.voucherGroups = updatedVoucherGroups;
        newVoucherGroups.forEach((voucherGroup) => {
          if (voucherGroup.groupLinkTxt && !this.groupLinkTxt) {
            this.groupLinkTxt = voucherGroup.groupLinkTxt;
          }
        });
      });
      // this.voucherGroups = voucherGroups;
      // console.log('voucherGroups', this.voucherGroups);
    });
  }

  async navigate(voucherGroupId) {
    this.waiting = true;
    // if (this.groupLinkForm.valid) {
    // const voucherGroupId = this.groupLinkForm.value.voucherGroup;
    console.log('voucherGroupId', voucherGroupId);

    const voucherGroup = (await (
      await this.db
        .doc(`township/${this.townshipId}/voucherGroups/${voucherGroupId}`)
        .get()
        .toPromise()
    ).data()) as VoucherGroup;
    console.log('voucherGroup', voucherGroup);

    let url = environment.iframeUrl;
    if (voucherGroup.type === 'sell') {
      url += 'buy/';
    } else {
      url += 'activate/';
    }
    url += `${this.townshipId}/${voucherGroupId}`;
    window.location.href = url;
    // }
    this.waiting = false;
  }
}
