import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VoucherGroup, Order, Township } from 'src/app/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buy-success',
  templateUrl: './buy-success.component.html',
  styleUrls: ['./buy-success.component.scss']
})
export class BuySuccessComponent implements OnInit {

  env = environment;
  redirectUrl: string;

  awaitingResponse: boolean;

  displayedColumns: string[] = ['Artikel', 'Aantal', 'Prijs'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: any[] = [];
  txt: string[] = [];

  failed = false;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  async ngOnInit() {

    this.awaitingResponse = true;
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      let res: Observable<any>;
      const requestUrl = `${this.env.functionsUrl}/httpGetOrder`;
      const postData = {
        voucherGroupdId: params.voucherGroupdId,
        townshipId: params.townshipId,
        orderId: params.orderId
      };

      res = this.http.post(requestUrl, postData);
      res.subscribe(async (result) => {
        console.log('result', result);
        if (result) {
          const voucherGroup = result.voucherGroup as VoucherGroup;
          const order = result.order as Order;
          const township = result.township as Township;
          if (voucherGroup.redirectUrl) {
            this.redirectUrl = voucherGroup.redirectUrl;
          }
          if (order.status === 'done' || order.status === 'paid') {
            this.txt.push(await this.getTranslation('succeed.message-above'));
            this.txt.push(await this.getTranslation('succeed.message-under'));
            // this.txt.push('Joepie, het betalen is gelukt.');
            // this.txt.push(' Wij gaan voor jou aan de slag:');
            let envName = township.voucherPrefix;
            envName = envName.charAt(0).toUpperCase() + envName.slice(1);
            this.data = [
              {
                'Artikel': `${envName} Bon voor €${order.price}`,
                'Aantal': `${order.amount}x`,
                'Prijs': `	€${order.price}`
              },
              {
                'Artikel': 'Administratiekosten',
                'Aantal': `1x`,
                'Prijs': `	€${order.totalPrice - (order.price * order.amount)}`
              },
              {
                'Artikel': 'Totaal',
                'Aantal': ``,
                'Prijs': `	€${order.totalPrice}`
              }
            ];
          } else if (order.status === 'expired') {
            this.txt.push(await this.getTranslation('failed.message-above'));
            this.txt.push(await this.getTranslation('failed.message-under'));
            // this.txt.push('Oeps, de order is geannuleerd. Er wordt niets in rekening gebracht.');
            // this.txt.push('Wil je alsnog bestellen? Bestel dan opnieuw.');
            this.failed = true;
          } else {
            this.txt.push(await this.getTranslation('processing.message-above'));
            this.txt.push(await this.getTranslation('processing.message-under'));
            // this.txt.push('Dankjewel! Je betaling wordt nog verwerkt.');
            // this.txt.push('Herlaadt de pagina over enkele seconden.');
          }
          this.awaitingResponse = false;
        }
      });
    });
  }

  openWindow(url: string) {
    // window.open(url);
    window.location.href = url;
  }

  getTranslation(objName): any {
    const res = this.translate.get(`buy.${objName}`).toPromise();
    return res;
  }
}


// add spinner