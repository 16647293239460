import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { Address, Township, VoucherGroup } from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { VerifyPhoneComponent } from './dialogs/verify-phone/verify-phone.component';
@Component({
  selector: 'app-activate-voucher',
  templateUrl: './activate-voucher.component.html',
  styleUrls: ['./activate-voucher.component.scss'],
})
export class ActivateVoucherComponent implements OnInit {
  env = environment;
  iframeError = true;
  awaitingResponse: boolean;
  townshipId: string;
  voucherGroupId: string;
  township: Observable<Township>;
  townshipDoc: AngularFirestoreDocument<Township>;
  voucherGroup: Observable<VoucherGroup>;
  voucherGroupData: VoucherGroup;
  voucherGroupDoc: AngularFirestoreDocument<VoucherGroup>;
  activateVoucherForm: FormGroup;
  availableVouchers: number;
  termsRows: number;
  loadingAddressSuffixes: boolean;
  lastFormValue: any;

  environment: string;
  error: string;

  headerImg: string;
  recaptcha: boolean;

  acitvateDateNotPassed: boolean;
  usableFromDate: any;

  envName: any = {};
  public addresses: ReplaySubject<Address[]> = new ReplaySubject<Address[]>(1);

  // constructor(private route: ActivatedRoute, public db: AngularFirestore) { }
  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.activateVoucherForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      emailConfirm: ['', [Validators.required, Validators.email]],
      terms: [false, Validators.requiredTrue],
      // phone: [],
    });
    this.activateVoucherForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(async (form) => {
        if (form.houseNumber) {
          form.houseNumber = form.houseNumber.toString();
        } else {
          form.houseNumber = '';
        }
        // console.log('form', form);
        const lastFormValue = { ...this.lastFormValue };
        this.lastFormValue = form;
        // console.log('lastFormValue', lastFormValue);
        // If postal and housenumber are set get possible suffixes
        if (
          form.postal &&
          form.houseNumber &&
          form.postal.length > 0 &&
          form.houseNumber.length > 0 &&
          (lastFormValue.postal !== form.postal ||
            lastFormValue.houseNumber !== form.houseNumber)
        ) {
          this.loadingAddressSuffixes = true;
          const addressesRef = this.db.collection(
            `/township/${this.townshipId}/addresses/`,
            (ref) =>
              ref
                .where('postal', '==', form.postal)
                .where('houseNumber', '==', form.houseNumber)
          );
          const addressesDocs = (await addressesRef.get().toPromise()).docs;
          // console.log('addressesDocs', addressesDocs);
          const addresses = [];
          addressesDocs.forEach((addressDoc) => {
            const address = addressDoc.data();
            if (address.houseNumberAddition) {
              addresses.push(address);
            } else if (addressesDocs.length > 1) {
              address.houseNumberAddition = '';
              addresses.unshift(address);
            }
          });
          // console.log('addresses', addresses);
          if (addresses.length > 0) {
            this.addresses.next(addresses);
          } else {
            this.addresses.next(null);
          }
        }
        // If postal or housenumber we're set, delete suffixes to let people enter their own suffixes again
        if (
          (!form.postal || !form.houseNumber) &&
          (lastFormValue.postal || lastFormValue.houseNumber)
        ) {
          this.loadingAddressSuffixes = false;
          this.addresses.next(null);
        }
      });

    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
      this.voucherGroupId = params.voucherGroupId;
      if (this.townshipId && this.voucherGroupId) {
        this.getInfo();
      }
    });

    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    // this.translate.get('HOME.HELLO', { value: 'ddd' }).subscribe((res: string) => {
    //   console.log(res);
    //   this.translationTest = res;
    //   //=> 'hello world'
    // });
  }
  verifyPhone(phone: string) {
    const dialogRef = this.dialog.open(VerifyPhoneComponent, {
      width: '300px',
      data: { townshipId: this.townshipId, phone },
      disableClose: true,
      // data: { type },
    });
    dialogRef.afterClosed().subscribe(async (code) => {
      if (code) {
        this.submit(code !== 'skip' ? code : null);
      }
    });
  }
  getError(name) {
    const field = this.activateVoucherForm.get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('startsWith')) {
        error = 'muststartwith06';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.get(`errors.${error}`).toPromise() as any;
      // console.log('res', res.__zone_symbol__value);
      return res.__zone_symbol__value as string;
    }
    return '';
  }
  getInfo() {
    this.townshipDoc = this.db.doc<Township>('township/' + this.townshipId);
    console.log('townshipRef', this.townshipDoc.ref.path);
    this.township = this.townshipDoc.valueChanges();
    this.township.subscribe((township) => {
      console.log('township', township);
      if (township.headerImageUrl) {
        this.headerImg = township.headerImageUrl;
      } else {
        if (township.voucherPrefix === 'groene') {
          this.headerImg = '../../assets/images/groene.png';
        } else if (township.voucherPrefix === 'toegangs') {
          this.headerImg = '../../assets/images/toegangsbon.png';
        } else if (township.voucherPrefix === 'duurzaamwonen') {
          this.headerImg = '../../assets/images/duurzaamwonen.png';
        } else {
          this.headerImg = '../../assets/images/lokale.png';
        }
      }
      if (township.voucherPrefix === 'groene') {
        this.envName = { value: 'Groene Bon' };
      } else if (township.voucherPrefix === 'toegangs') {
        this.envName = { value: 'Toegangsbon' };
      } else if (township.voucherPrefix === 'duurzaamwonen') {
        this.envName = { value: 'Duurzaam Wonen Bon' };
      } else {
        this.envName = { value: 'Lokale Bon' };
      }
    });
    console.log('this.voucherGroupId', this.voucherGroupId);
    this.voucherGroupDoc = this.db.doc<VoucherGroup>(
      'township/' + this.townshipId + '/voucherGroups/' + this.voucherGroupId
    );
    // this.voucherGroup = this.voucherGroupDoc.valueChanges();
    this.voucherGroup = this.voucherGroupDoc.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as VoucherGroup;
        this.termsRows = 5;
        console.log('data', data);
        data.id = actions.payload['id'];

        if (data.requestName) {
          this.activateVoucherForm.addControl(
            'name',
            new FormControl('', Validators.required)
          );
        }
        if (data.requestPhone) {
          if (data.verifyPhone) {
            this.activateVoucherForm.addControl(
              'phone',
              new FormControl('06', [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10),
                startsWith('06'),
              ])
            );
          } else {
            this.activateVoucherForm.addControl(
              'phone',
              new FormControl('06', [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10),
              ])
            );
          }
        }
        if (data.type === 'claim') {
          this.activateVoucherForm.addControl(
            'number',
            new FormControl('', Validators.required)
          );
        }

        if (!data.dontRequestAddress) {
          this.activateVoucherForm.addControl(
            'postal',
            new FormControl('', Validators.required)
          );
          this.activateVoucherForm.addControl(
            'houseNumber',
            new FormControl('', Validators.required)
          );
          this.activateVoucherForm.addControl(
            'houseNumberAddition',
            new FormControl('')
          );
        }

        data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
        data.activatedVouchers = data.activatedVouchers
          ? data.activatedVouchers
          : 0;

        if (data.maxVouchers && data.totalVouchers > data.maxVouchers) {
          data.totalVouchers = data.maxVouchers;
        }
        if (!data.claimedVouchers) {
          data.claimedVouchers = 0;
        }
        if (!data.activatedVouchers) {
          data.activatedVouchers = 0;
        }
        if (!data.reservedVouchers) {
          data.reservedVouchers = 0;
        }
        if (!data.toBeActivated) {
          data.toBeActivated = 0;
        }
        const reservedVouchers = data.reservedVouchers + data.toBeActivated;
        this.availableVouchers =
          data.totalVouchers - data.activatedVouchers - reservedVouchers;
        if (data.maxVouchers && data.maxVouchers < data.totalVouchers) {
          this.availableVouchers = data.maxVouchers - data.activatedVouchers;
        }
        if (data.activateFromType === 'activateOnDate') {
          if (data.usableFrom.toDate() > new Date()) {
            this.usableFromDate = data.usableFrom.toDate();
            this.acitvateDateNotPassed = true;
            console.log('activate date nog passed');
          }
        }

        console.log('availableVouchers', this.availableVouchers);
        if (this.availableVouchers <= 0 && data.type !== 'requestActivated') {
          this.activateVoucherForm.disable();
        } else if (!this.awaitingResponse) {
          this.activateVoucherForm.enable();
        }

        if (data.useHeaderImage) {
          this.headerImg = data.headerImageUrl;
        }
        return { ...data };
      })
    );
    this.voucherGroup.subscribe((voucherGroup) => {
      console.log('voucherGroup', voucherGroup);
      this.voucherGroupData = voucherGroup;
    });
  }
  async submit(verificationCode?: string) {
    if (!this.awaitingResponse && this.activateVoucherForm.valid) {
      this.awaitingResponse = true;
      const form = this.activateVoucherForm.value;
      this.activateVoucherForm.disable();
      const email = form.email as string;
      const emailConfirm = form.emailConfirm as string;
      if (email !== emailConfirm) {
        this.awaitingResponse = undefined;
        this.activateVoucherForm.enable();
        this.snackBar.open(this.getTranslation('email-dont-match'), '', {
          duration: 5000,
        });
        return;
      }
      let postal: string;
      let houseNumber: string;
      let houseNumberAddition: string;
      if (!this.voucherGroupData.dontRequestAddress) {
        postal = form.postal as string;
        postal = postal.toUpperCase().replace(/\s/g, '');
        houseNumber = form.houseNumber.toString();
        houseNumberAddition = form.houseNumberAddition as string;
      }
      const code = form.number ? form.number : null;
      const name = form.name ? form.name : null;
      const phone = form.phone ? form.phone : null;
      let res: Observable<any>;

      const requestUrl = `${this.env.functionsUrl}/httpVoucher`;

      res = this.http.post(
        requestUrl,
        {
          townshipId: this.townshipId,
          voucherGroupId: this.voucherGroupId,
          email,
          postal: postal ? postal : null,
          houseNumber: houseNumber ? houseNumber : null,
          houseNumberAddition:
            houseNumberAddition && houseNumberAddition.length
              ? houseNumberAddition
              : null,
          code: code ? code : null,
          name: name ? name : null,
          phone: phone ? phone : null,
          verificationCode: verificationCode ? verificationCode : null,
        },
        { responseType: 'text' }
      );
      res.subscribe((result) => {
        this.awaitingResponse = undefined;
        console.log('result', result);
        if (
          result === 'verification_code_sent' ||
          result === 'verification_code_incorrect'
        ) {
          // Should show verification screen
          // this.showVerificationForm = true;
          if (result === 'verification_code_incorrect') {
            this.getTranslation(result);
          }
          this.verifyPhone(phone);
        } else if (result === 'success') {
          this.activateVoucherForm.reset();
          this.getTranslation(result);
        } else {
          this.getTranslation(result);
        }
        this.activateVoucherForm.enable();
      });
    }
  }

  getTranslation(objName: string) {
    let message = (
      this.translate.get(`snackbar-messages.${objName}`).toPromise() as any
    ).__zone_symbol__value as string;
    if (message.includes('snackbar-messages.')) {
      // translation not found, get oops error instead
      message = (
        this.translate
          .get(`snackbar-messages.something-went-wrong`)
          .toPromise() as any
      ).__zone_symbol__value as string;
    }
    this.snackBar.open(message, 'X', {
      duration: 12000,
    });
    return message;
  }
}
export function startsWith(startsWithVal): ValidatorFn {
  console.log('startsWithVal', startsWithVal);

  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value ? control.value : '';
    const isStarting = 0 === value.indexOf(startsWithVal);
    return isStarting ? null : { startsWith: true };
  };
}
