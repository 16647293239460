import { Component, OnInit } from '@angular/core';
// import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
// import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';

import { environment } from '../../environments/environment';
import { User } from '../interfaces';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  userDoc: AngularFirestoreDocument<User>;
  userId: Observable<string>;
  user: Observable<User>;
  fixed = true;
  // select_entity: boolean;
  userEmail: string;
  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  //   .pipe(
  //     map(result => result.matches)
  //   );

  constructor(
    private route: Router, public afAuth: AngularFireAuth,
    public afs: AngularFirestore) {
    // this.userEmail = this.constant.userEmail;
    this.afAuth.user.subscribe(user => {
      if (user) {
        // console.log('user', user);
        this.userEmail = user.email;
        this.userDoc = afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe(res => {
          console.log('UserData =>', res);
        });
        console.log('environment.name.name', environment.name.name);
        // if (environment.name.name === 'Bob de Bot') {
        //   this.select_entity = true;
        // } else {
        //   this.select_entity = false;
        // }
        // console.log('userEmail', this.userEmail);
        // console.log('this.select', this.select_entity);
        // waiting for select entity page
        // this.userTags = afs.doc<Tags>('users/' + user.uid + '/communities' + '/exampleCommunity');
        // this.community = this.userTags.valueChanges();
        // console.log('this.community',this.community);
      }
    });
  }

  async logout() {
    console.log('User pressed button');
    // await this.afAuth.auth.signOut();
    return this.route.navigate(['/login']);
  }

  // logout() {
  //   console.log("user logged out");
  //   this.afAuth.auth.signOut();
  // }

  ngOnInit() {
    // this.afAuth.auth.onAuthStateChanged(function(user) {
    //   if (user) {
    //     console.log("user =>", user.email)
    //     this.checkUser = true;
    //   } else {
    //     // User is signed out.
    //     // ...
    //   }
    // });
  }

}
