import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Township, VoucherGroup } from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activate-voucher',
  templateUrl: './request-vouchers.component.html',
  styleUrls: ['./request-vouchers.component.scss'],
})
export class RequestVouchersComponent implements OnInit {
  env = environment;
  awaitingResponse: boolean;
  townshipId: string;
  type: string;
  township: Observable<Township>;
  townshipDoc: AngularFirestoreDocument<Township>;
  requestForm: FormGroup;

  reqDigital: boolean;
  environment: string;
  error: string;
  recaptcha: boolean;

  recaptchaKey: string;
  headerImg: string;
  envName: any;

  // constructor(private route: ActivatedRoute, public db: AngularFirestore) { }
  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.requestForm = this.fb.group({
      email: [, [Validators.required, Validators.email]],
      emailConfirm: [, [Validators.required, Validators.email]],
      voucher: [, Validators.required],
      // phone: [],
    });
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.recaptcha = false;
    this.recaptchaKey = this.env.recaptchaPublicKey;
    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
      this.type = params.type;
      if (this.type === 'digital') {
        this.townshipDoc = this.db.doc<Township>('township/' + this.townshipId);
        this.township = this.townshipDoc.valueChanges();
        this.township.subscribe((township) => {
          console.log('township', township);
          if (township.headerImageUrl) {
            this.headerImg = township.headerImageUrl;
          } else {
            if (township.voucherPrefix === 'groene') {
              this.headerImg = '../../assets/images/groene.png';
            } else if (township.voucherPrefix === 'toegangs') {
              this.headerImg = '../../assets/images/toegangsbon.png';
            } else if (township.voucherPrefix === 'duurzaamwonen') {
              this.headerImg = '../../assets/images/duurzaamwonen.png';
            } else {
              this.headerImg = '../../assets/images/lokale.png';
            }
          }
          if (township.voucherPrefix === 'groene') {
            this.envName = { value: 'Groene Bon' };
          } else if (township.voucherPrefix === 'toegangs') {
            this.envName = { value: 'Toegangsbon' };
          } else if (township.voucherPrefix === 'duurzaamwonen') {
            this.envName = { value: 'Duurzaam Wonen Bon' };
          } else {
            this.envName = { value: 'Lokale Bon' };
          }
        });
      }
    });

    this.requestForm.valueChanges.subscribe(() => {
      //pipe(debounceTime(100))
      if (this.error) {
        this.error = '';
      }
    });
  }
  getError(name) {
    const field = this.requestForm.get(name);
    if (field.touched || !field.pristine) {
      let error;

      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.get(`errors.${error}`).toPromise() as any;
      // console.log('res', res.__zone_symbol__value);
      return res.__zone_symbol__value as string;
    }
  }

  requestVoucher(): void {
    this.awaitingResponse = true;
    if (this.requestForm.valid) {
      const data = this.requestForm.value;

      if (data.email !== data.emailConfirm) {
        this.requestForm.controls.emailConfirm.setErrors({
          notMatched: true,
        });
        this.awaitingResponse = false;
        this.error = this.getTranslation('email-dont-match');
        return;
      }
      const requestUrl = `${this.env.functionsUrl}/httpReqDigitalVoucher?townshipId=${this.townshipId}&voucher=${data.voucher}&email=${data.email}`;
      let res: Observable<any>;
      res = this.http.get(requestUrl, { responseType: 'text' });
      res.subscribe((result) => {
        console.log('result', result);
        switch (result) {
          case 'succeed':
            this.requestForm.reset();
            this.snackBar.open(this.getTranslation('bon-sent'), '', {
              duration: 12000,
            });
            break;
          case 'not_purchased':
            this.error = this.getTranslation('not_purchased');
            this.requestForm.controls.voucher.setErrors({
              notMatched: true,
            });
            break;
          case 'unknown_voucher':
            this.error = this.getTranslation('doesnt_exists');
            this.requestForm.controls.voucher.setErrors({
              notMatched: true,
            });
            break;
          case 'email_error':
            this.error = this.getTranslation('email_already_used');
            this.requestForm.controls.voucher.setErrors({
              notMatched: true,
            });
            break;
        }
        this.awaitingResponse = false;
      });
    }
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse) {
      this.recaptcha = true;
    } else {
      this.recaptcha = false;
    }
  }

  getTranslation(objName): string {
    const message = this.translate
      .get(`snackbar-messages.${objName}`)
      .toPromise() as any;
    return message.__zone_symbol__value;
  }
}
