import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  phone: string;
  townshipId: string;
}
@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
})
export class VerifyPhoneComponent implements OnInit {
  verifyPhoneForm: FormGroup;

  constructor(
    public db: AngularFirestore,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<VerifyPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.verifyPhoneForm = this.fb.group({
      code: ['', [Validators.required]],
    });
    // checks if phone has heartbeat
    const smsCentralErrorsDoc = this.db
      .doc(
        `township/${this.data.townshipId}/smsCentralErrors/${this.data.phone}`
      )
      .valueChanges();
    smsCentralErrorsDoc.subscribe((doc) => {
      console.log('doc', doc);
      if (doc) {
        this.dialogRef.close('skip');
      }
    });
  }

  submit() {
    if (this.verifyPhoneForm.valid) {
      this.dialogRef.close(this.verifyPhoneForm.value.code);
    }
  }
}
